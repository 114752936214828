import React, { useState } from "react";
import '../../css/login.css'
import Logo from '../../assets/images/logo.png'
import seePasswordIcon from "../../assets/images/icons/eye.png";
import hidePasswordIcon from "../../assets/images/icons/eye1.png";
import { Link } from "react-router-dom";
// import { LoginUser } from "../../services/authService"; // Ensure this service is implemented correctly
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";
// import SetLoadingScreen from "../../components/SetLoader";
// import { useUIContext } from "../../context";

const Login = () => {
  const [open, setOpen] = useState(false);
  const [userType, setUserType] = useState(null);
  const [redirectToHome, setRedirectToHome] = useState(false);
//   const { fetchProfileData } = useUIContext();
//   const {loading, setloading} = useUIContext();
  const [credentials, setCredentials] = useState({
    customerID: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  // ----------------------------------- login api -----------------------------------------

//   const validateForm = () => {
//     let formErrors = {};

//     if (!credentials.customerID.trim()) {
//       formErrors.customerID = "Customer Id is required";
//     }
//     if (!credentials.password.trim()) {
//       formErrors.password = "Password is required";
//     }
//     setErrors(formErrors);
//     return Object.keys(formErrors).length === 0;
//   };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setCredentials((prevState) => ({ ...prevState, [name]: value }));
  };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateForm()) {
//       return;
//     }
//     setloading(true);
//     try {
//       const response = await LoginUser({ payload: credentials });

//       if(response.success){
//         await fetchProfileData()
//         setloading(false);
//         setUserType(response?.data?.user_role);
//         setRedirectToHome(true);
//       }
//     } catch (error) {
//       setloading(false);  
//       toast.error(error?.response?.data?.message);
//     }
//   };

  if (redirectToHome) {

    if (userType === "customer") {
      return <Navigate to="/home" />;
    }

    if (userType === "admin") {
      return <Navigate to="/admin/home" />;
    }

    if (userType === "employee") {
      return <Navigate to="/employe/home" />;
    }
    // return <Navigate to="/" />;
  }


  return (
    <>
    

      {/* <SetLoadingScreen loading={loading}/> */}
      <div className="LoginContain">
        <div className="LoginLogo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="LoginCard">
          <div className="LoginTitle">
            <h4>Login</h4>
            <p>EasyGo Audit support team is just a tap away!</p>
          </div>
          <form >
            <div className="LoginGroup">
              <label htmlFor="customerID">User ID <span className="RequieredStar">*</span></label>
              <input
                type="text"
                id="customerID"
                name="customerID"
                // value={credentials.customerID}
                placeholder="Enter Your ID"
                // onChange={handleChanges}
              />
              {/* {errors.customerID && (
                <span className="error">{errors.customerID}</span>
              )} */}
            </div>
            <div className="LoginGroup">
              <label htmlFor="password">Password <span className="RequieredStar">*</span></label>
              <div className="PasswrdInpt">
                <input
                  type={open ? "text" : "password"}
                  id="password"
                  name="password"
                //   value={credentials.password}
                  placeholder="Enter Your Password"
                  onChange={handleChanges}
                />
                {/* {errors.password && (
                  <span className="error">{errors.password}</span>
                )} */}
                <button type="button" onClick={() => setOpen(!open)}>
                  <img
                    src={open ? hidePasswordIcon : seePasswordIcon}
                    alt="Toggle visibility"
                  />
                </button>
              </div>
            </div>
            <div className="LoginGroup CheckBox">
              <Link to="/forgotpassword">Forgot Password?</Link>
            </div>
            <div className="LoginGroup LoginBtn">
              <Link to="/home"><button type="submit" >Login</button></Link>
            </div>
          </form>
        </div>
      </div>
      <div className="LoginFooter">
        <p>
          POWERED BY: <span>Manthan IT Solutions</span>
        </p>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default Login;
