import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2'; // Import the Doughnut chart component
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin for data labels

// Register the necessary Chart.js components and the plugin
ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);


const DoughnutChart = () => {
  // State to hold the chart data
  const [data, setData] = useState({
    labels: ['Total', 'Submitted', 'Accepted', 'Reconsideration', 'Pending'], // Category labels
    datasets: [
      {
        data: [6, 2, 1, 1, 2], // Values for each segment
        backgroundColor: ['blue', '#71BC78', 'green', '#f9b94b', 'red'], // Segment colors
        borderColor: ['blue', '#71BC78', 'green', '#f9b94b', 'red'],
        borderWidth: 1, // Border width
      },
    ],
  });

  // Calculate total manually from dataset
  const calculateTotal = (dataset) => {
    return dataset.data.reduce((total, value) => total + value, 0);
  };

  // Chart options to customize the appearance
  const options = {
    responsive: true,
    plugins: {
      // Plugin to show raw values inside the doughnut chart
      datalabels: {
        display: true, // Ensure data labels are displayed
        color: '#fff', // Text color for the data label
        formatter: (value) => {
          if (value === 0) return null; // Hide labels for zero values
          return value; // Show value if it's not zero
        },
        anchor: 'center', // Anchor the text at the center of the segment
        align: 'center', // Align the text at the center of the segment
        font: {
          weight: 'bold', // Make the font bold
          size: 16, // Font size for the values
        },
      },
      // Hide the legend at the bottom
      legend: {
        display: false, // Set display to false to hide the legend
      },
      tooltip: {
        enabled: false, // Disable tooltip if you don't want any hover effects
      },
    },
  };

  return (
    <div className='ChartContain'>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutChart;
