import React, { useState, useRef, useEffect } from 'react';
import "../../css/sendotp.css";
import { Link } from 'react-router-dom';

function Sendotp() {
    const [otp, setOtp] = useState(['', '', '', '']);
    const [timer, setTimer] = useState(60);
    const otpInputRefs = useRef([]);


    const handleChange = (value, index) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value && index < otpInputRefs.current.length - 1) {
            otpInputRefs.current[index + 1].focus();
        }
    };

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
            return () => clearInterval(countdown);
        }
    }, [timer]);


    const handleResend = () => {
        setTimer(60);
        setOtp(['', '', '', '']);
        otpInputRefs.current[0].focus();
    };

    const handleSubmit = () => {
        alert(`Entered OTP: ${otp.join('')}`);
    };

    return (

        <div className="sendotp-container">
            <div className='forgot_box forgot_box_w'>
                <h1 className="sendotp-title">Reset Your Password</h1>
                <label className="sendotp-label">Enter your OTP</label>
                <div className="otp-input-container">
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength="1"
                            value={digit}
                            onChange={(e) => handleChange(e.target.value, index)}
                            ref={(el) => otpInputRefs.current[index] = el}
                            className="otp-input"
                        />
                    ))}
                </div>
                <div className="timer-resend-container">
                    {timer > 0 ? (
                        <span className="timer">Time Remaining: {timer} seconds</span>
                    ) : (
                        <button onClick={handleResend} className="resend-button">Resend OTP</button>
                    )}
                </div>
                {timer > 0 && (
                    <Link className="Link" to={"/new-password"}>
                        <button onClick={handleSubmit} className="submit-button">Submit</button>
                    </Link>
                )}
            </div>
        </div>
    );
}

export default Sendotp;
