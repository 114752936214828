import React from 'react'
import "../css/home.css";
import Tabs from '../Components/home/Tabs';
import { ParentContain } from '../style/Home';

function Home() {
  return (
    <ParentContain>
       <Tabs/>
    </ParentContain>
  )
}

export default Home

