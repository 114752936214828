import React from 'react'
import { Link } from 'react-router-dom'

function Getotp() {
    return (
        <div className="forgot-container">
            <div className='forgot_box'>
                <h1 className="forgot-title">Reset Your Password</h1>
                <div className="input_field">
                    <label htmlFor="user-id" className="forgot-label">User Email</label>
                    <input type="text" id="user-id" className="forgot-input" placeholder="g******tyd@gmail.com" />
                </div>
                <div className="input_field">
                    <label htmlFor="user-id" className="forgot-label">User Mobile</label>
                    <input type="text" id="user-id" className="forgot-input" placeholder="******5647" />
                </div>
                <Link className="Link" to={"/send-otp"}>
                    <button className="forgot-button">Send OTP</button>
                </Link>

            </div>
        </div>
    )
}

export default Getotp