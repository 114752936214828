import React from 'react'
import { Link } from 'react-router-dom'

function Newpassword() {
    return (
        <div className="forgot-container">
            <div className='forgot_box'>
                <h1 className="forgot-title">Reset Your Password</h1>
                <div className="input_field">
                    <label htmlFor="user-id" className="forgot-label">Enter new Password</label>
                    <input type="text" id="user-id" className="forgot-input" placeholder="New Password" />
                </div>
                <div className="input_field">
                    <label htmlFor="user-id" className="forgot-label">Confirm Password</label>
                    <input type="text" id="user-id" className="forgot-input" placeholder="Confirm Password" />
                </div>
                <Link className="Link" to={"/"}>
                    <button className="forgot-button">Password Reset</button>
                </Link>

            </div>
        </div>
    )
}

export default Newpassword