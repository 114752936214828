import  React , { useState, useEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import '../../css/component/tabs.css';
import Grid from '@mui/material/Grid2';
import DoughnutChart from '../charts/Chart';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ButtonContain, Card, CardContain, CardFooter, CardTitle, CustomButtonContain, FieldContain, TableContain, TabsContain } from '../../style/Home';
const MyTabs = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isSticky, setIsSticky] = useState(false); // To track scroll position




  useEffect(() => {
    console.log("----------21-->",process.env.API_URL)
    console.log("----------1-->",process.env)
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true); // Add 'sticky' class when scroll > 56px
      } else {
        setIsSticky(false); // Remove 'sticky' class
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <TabsContain>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs example"
        className={`HomeTabs ${isSticky ? "StickyShadow" : ""}`} 
      >
        <Tab label="MVR" value="1" />
        <Tab label="QVR" value="2" />
      </Tabs>

      {value === "1" && (
        <>
              <CardContain>
              <Card mt={1}>
              <Grid container spacing={2}>
                <Grid item size={{ xs: 6 }}>
                    <FieldContain>
                        <input type="text" value="2024-2025" />
                    </FieldContain>
                </Grid>
                <Grid item size={{ xs: 6 }}>
                <FieldContain>
                    <select name="month" id="month">
                      <option value="November">November</option>
                      <option value="October">October</option>
                    </select>
                    </FieldContain>
                </Grid>
                <Grid item size={{ xs: 12 }}>
                   <FieldContain>
                    <select name="month" id="month">
                      <option value="">Select AMD</option>
                      <option value="nikhil">Nikhil Kumar</option>
                    </select>
                    </FieldContain>
                </Grid>
                <Grid item size={{ xs: 12 }}>
                    <ButtonContain>
                    <button className="SearchButton">Search</button>
                    <button className="ResetButton">Reset</button>
                    </ButtonContain>
                </Grid>
              </Grid>
              </Card>
              </CardContain>
              
          <CardContain mt={2}>
              <CardTitle variant='h4'>MVR Status</CardTitle>
              <Card>
                <Grid container spacing={2}>
                  <Grid item size={{ xs: 6 }}>
                    <div className="StatusCard">
                      <ul>
                        <li>
                          Total AMD :
                          <span className="StatusCount total">6</span>
                        </li>
                        <li>
                          Submitted By AM :
                          <span className="StatusCount submitted">2</span>
                        </li>
                        <li>
                          Accepted By AMD :
                          <span className="StatusCount accepted">1</span>
                        </li>
                        <li>
                          Reconsidertion By AMD :
                          <span className="StatusCount reconsidertion">1</span>
                        </li>
                        <li>
                          Pending By AM :
                          <span className="StatusCount pending">2</span>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item size={{ xs: 6 }}>
                      <DoughnutChart />
                  </Grid>
                </Grid>
              </Card>
              <CardFooter>
                <div><p> Total MVR Submitted : <span className="MVRCount">1</span></p></div>
                
                  <CustomButtonContain>
                  <button>AMD Wise Status <KeyboardDoubleArrowRightIcon/></button>
                  </CustomButtonContain>
              </CardFooter>
          </CardContain>

          <CardContain mt={2}>
            <TableContain>
              <table className='table'>
                <thead>
                  <tr className='TableTitle'>
                  <th colSpan={7}><h4 >MVR-MoM Status</h4></th>
                  </tr>
                  <tr>
                    <th>Total MoM Points</th>
                    <th>Open Mom Points</th>
                    <th>MoM Points Completed By Dealers</th>
                    <th>MoM Points Completed By AM</th>
                    <th>Escalated MoM Points at AM(Cum.)</th>
                    <th>Escalated MoM Points at ZM(Cum.)</th>
                    <th>Escalated MoM Points at RM(Cum.)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6</td>
                    <td>6</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </TableContain>
              <CardFooter>
                <div><p> Carry forward open points : <span className="MVRCount">0</span></p></div>
                
                  <CustomButtonContain>
                  <button>MVR MoM Status <KeyboardDoubleArrowRightIcon/></button>
                  </CustomButtonContain>
              </CardFooter>
          </CardContain>
          </>
      )}
      {value === "2" && <Box p={2}>QVR</Box>}
    </TabsContain>
  );
};

export default MyTabs;
