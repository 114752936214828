import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Login from './pages/login/Login';
import './App.css';
import Sidebar from './Components/header/Sidebar';
// import Footer from './Components/footer/Footer';
import Home from './pages/Home';
import Forgot from './pages/login/Forgot';
import GetOtp from './pages/login/Getotp';
import SendOtp from './pages/login/Sendotp';
import NewPassword from './pages/login/Newpassword';
import Footer from './Components/footer/Footer';
// import Footer from './Components/footer/Footer';

function App() {
  const location = useLocation();
  const showHeaderFooter = location.pathname !== '/' && location.pathname !== '/forgot' && location.pathname !== '/get-otp' && location.pathname !== '/send-otp' && location.pathname !== '/new-password';

  return (
    <div className="App">
      
      {showHeaderFooter && <Sidebar/>}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/get-otp" element={<GetOtp />} />
        <Route path="/send-otp" element={<SendOtp />} />
        <Route path="/new-password" element={<NewPassword />} />
      </Routes>
      {showHeaderFooter && <Footer />}
    </div>
  );
}

export default App;
