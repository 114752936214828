import styled from "@emotion/styled"; 
import { Typography } from "@mui/material";
import { Colors } from "../theme";

// Add const/let or export default if needed
export const  HeaderTitle = styled(Typography)(() => ({
  color: Colors.primary,
  textTransform: 'uppercase',
}));

