import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, Collapse, IconButton, AppBar, Toolbar, Typography } from '@mui/material';
import { ExpandMore, ExpandLess, Menu as MenuIcon, AccountCircle, Close } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PreviewIcon from '@mui/icons-material/Preview';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import DvrIcon from '@mui/icons-material/Dvr';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AddCardIcon from '@mui/icons-material/AddCard';
import '../../css/component/sidebar.css';
import { HeaderTitle } from '../../style/header/Header';

const Sidebar = () => {
  const [openDropdown, setOpenDropdown] = useState(null); // To track the currently open dropdown
  const [drawerOpen, setDrawerOpen] = useState(false); // To manage the visibility of the sidebar
  const location = useLocation(); // Hook to get the current route

  const navLinks = [
    { label: 'Dashboard', link: '/dashboard', icon: <DashboardIcon /> },
    {
      label: 'Visit Plan',
      link: '/visitplan',
      icon: <CalendarMonthIcon />
    },
    {
      label: 'MVR',
      link: '#',
      icon: <DvrIcon />,
      sublinks: [
        { label: 'Add MVR', link: '/plan', icon: <AddCardIcon /> },
        { label: 'MoM Status Update', link: '/astmview', icon: <PreviewIcon /> },
        { label: 'MoM Escalated Points', link: '/astmview', icon: <WorkHistoryIcon /> }
      ]
    },
    {
      label: 'QVR',
      link: '#',
      icon: <DvrIcon />,
      sublinks: [
        { label: 'Add QVR', link: '/plan', icon: <DateRangeIcon /> },
        { label: 'MoM Status Update', link: '/astmview', icon: <PreviewIcon /> },
        { label: 'MoM Escalated Points', link: '/astmview', icon: <WorkHistoryIcon /> },
        { label: 'Self Evaluation', link: '/astmview', icon: <WorkHistoryIcon /> }
      ]
    },
    {
      label: 'Manage Plan',
      link: '#',
      icon: <CalendarMonthIcon />,
      sublinks: [
        { label: 'Enter Retail Plan', link: '/plan', icon: <DateRangeIcon /> },
        { label: 'ASTM View', link: '/astmview', icon: <PreviewIcon /> },
        { label: 'Business Parameters', link: '/astmview', icon: <WorkHistoryIcon /> }
      ]
    },
    { label: 'Support', link: '/support', icon: <SupportAgentOutlinedIcon /> },
    { label: 'Log Out', link: '/logout', icon: <LogoutOutlinedIcon /> }
  ];

  const handleToggle = (label) => {
    setOpenDropdown((prev) => (prev === label ? null : label)); // Toggle open dropdown
  };

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev); // Toggle the drawer visibility
  };

  // Close the sidebar when the route changes
  useEffect(() => {
    setDrawerOpen(false); // Close the sidebar
  }, [location]); // Runs whenever the location (route) changes

  return (
    <div>
      {/* AppBar with the Menu Icon to toggle sidebar */}
      <AppBar position="fixed" sx={{ backgroundColor: 'white' }} className="Appbar">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{ mr: 2, color: '#000', fontSize: '24px' }}
          >
            <MenuIcon />
          </IconButton>
          <HeaderTitle variant="h6">EasyGo Audit</HeaderTitle>
          <button className="NotificationIcon">
            <NotificationsActiveIcon />
            <span className="NotificationCount"> 102</span>
          </button>
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Drawer
        sx={{
          width: 300,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 300,
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        anchor="left"
        open={drawerOpen} // Controls visibility based on state
        onClose={toggleDrawer} // Close the drawer when clicked outside
      >
        <div className="SideBarHeadContain">
          {/* Profile Icon on the left */}
          <div className="SideBar">
            <IconButton sx={{ padding: '0' }}>
              <AccountCircle fontSize="large" />
            </IconButton>

            {/* User's Name in the center */}
            <div className="user_name_text">
              <span>Nikhil Kumar</span>
              <span>Area Incharge</span>
              <span>45367</span>
            </div>
          </div>
          {/* Close Icon on the right */}
          <IconButton onClick={toggleDrawer} sx={{ padding: '0' }}>
            <Close />
          </IconButton>
        </div>

        <List className="SidebarList">
          {Array.isArray(navLinks) && navLinks.length > 0 ? (
            navLinks.map((link, index) => (
              <>
                {/* Render top-level item */}
                {!link.sublinks ? (
                  <ListItem button key={index} className="Border_top">
                    <Link
                      to={link.link}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      className="LinkSideBar"
                    >
                      {link.icon}
                      <ListItemText primary={link.label} />
                    </Link>
                  </ListItem>
                ) : (
                  <>
                    {/* Render item with sublinks */}
                    <ListItem
                      button
                      onClick={() => handleToggle(link.label)} // Handle toggle for sublinks
                      aria-haspopup="true"
                      className="LinkSideBar Border_top"
                      key={index}
                    >
                      {link.icon}
                      <ListItemText primary={link.label} />
                      <IconButton>
                        {openDropdown === link.label ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </ListItem>

                    {/* Render sublinks */}
                    <Collapse in={openDropdown === link.label} timeout="auto" unmountOnExit className="ContainDropDown">
                      <List disablePadding ms={2} className="DropDown">
                        {Array.isArray(link.sublinks) && link.sublinks.length > 0 ? (
                          link.sublinks.map((sublink, subIndex) => (
                            <ListItem key={subIndex} button sx={{ paddingLeft: 4 }}>
                              <Link
                                to={sublink.link}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                                className="LinkSideBar"
                              >
                                {sublink.icon}
                                <ListItemText primary={sublink.label} />
                              </Link>
                            </ListItem>
                          ))
                        ) : (
                          <ListItem button>
                            <ListItemText primary="No sublinks available" />
                          </ListItem>
                        )}
                      </List>
                    </Collapse>
                  </>
                )}
              </>
            ))
          ) : (
            <ListItem button>
              <ListItemText primary="No nav links available" />
            </ListItem>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
