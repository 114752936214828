import React from 'react';
import "../../css/forgot.css";
import { Link } from 'react-router-dom';

function Forgot() {
    return (
        <div className="forgot-container">
            <div className='forgot_box'>
                <h1 className="forgot-title">Reset Your Password</h1>
                <label htmlFor="user-id" className="forgot-label">Enter User ID</label>
                <input type="text" id="user-id" className="forgot-input" placeholder="User ID" />
                <Link className="Link" to={"/get-otp"}>
                <button className="forgot-button">Next</button>
                </Link>
                
            </div>
        </div>
    );
}

export default Forgot;
